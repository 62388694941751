<template>
  <div class="list-trade-in">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu định giá'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i class="fas fa-cog cog-font-size"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="excelModal">
                <span>
                  <i class="far fa-file-excel excel-font-size"></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filterdOptionsStore"
              :placeholder="'cửa hàng'"
              :searchable="true"
              :matchKey="['name']"
              :limit="5"
            />
          </b-col>
          <b-col>
            <div class="d-flex justify-content-start">
              <b-input
                placeholder="Tìm kiếm theo tên, mã, IMEI sản phẩm"
                v-model="searchProduct"
                class="form-control form-control-sm"
                v-on:keyup.enter="onFilter()"
              ></b-input>
            </div>
          </b-col>
          <b-col>
            <b-input
              placeholder="Tìm kiếm theo tên, SĐT KH"
              v-model="searchCustomer"
              class="form-control form-control-sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Trạng thái --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
          <b-col md="1">
            <b-button
              class="function-btn"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              hover
              bordered
              :busy="onLoading"
              responsive
              class="w-100"
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="6"
                ></vcl-table>
              </template>
              <template v-slot:cell(id)="row">
                <div class="text-left">
                  <div
                    class="productCode btn-link cursor-pointer"
                    @click="editItem(row.item)"
                  >
                    <span v-text="row.item.id"></span>
                  </div>

                  <p class="mb-0 font-weight-bolder">
                    {{ row.item.date }}
                  </p>
                  <p class="mb-0">
                    {{ row.item.createdBy }}
                  </p>
                </div>
              </template>
              <template v-slot:cell(type)="row">
                <div style="text-align: center">
                  <i
                    v-if="row.item.tradeInType === TRADE_IN_TYPE.TRADE_IN"
                    class="fa fa-retweet icon-color text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                  <i
                    v-else-if="row.item.tradeInType === TRADE_IN_TYPE.BUYING_OLD_ITEMS"
                    class="fa fa-cart-arrow-down text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                  <i
                    v-else
                    class="fa fa-exchange-alt text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                </div>
              </template>
              <template v-slot:cell(customer)="row">
                <p class="mb-0 font-weight-bolder">
                  {{ row.item.customerName }}
                </p>
                <p class="mb-0">
                  <a
                    class="font-weight-bold"
                    v-bind:href="getLinkDocCustomer(row.item)"
                    target="_self"
                  >
                    {{ row.item.customerPhone }}
                  </a>
                </p>
              </template>
              <template v-slot:cell(productName)="row">
                <p class="mb-0">
                  {{ row.item.productName }}
                </p>
                <p class="text-imei-no mb-0">IMEI: {{ row.item.imeiCode }}</p>
              </template>
              <template v-slot:cell(estimatePrice)="row">
                <div>
                  <span v-text="convertPrice(row.item.estimatePrice)"></span>
                </div>
              </template>
              <template v-slot:cell(finalPrice)="row">
                <div>
                  <span v-text="convertPrice(row.item.finalPrice)"></span>
                </div>
              </template>
              <template v-slot:cell(hasUploadImage)="row">
                <div>
                  <span v-bind:class="getUploadClass(row.item.hasUploadImage)">{{ row.item.hasUploadImage }}</span>
                </div>
              </template>
              <template v-slot:cell(statusName)="row">
                <div class="text-center">
                  <div class="mb-2">
                    <span
                      v-if="row.item.status === 1"
                      v-text="row.item.statusName"
                      class="
                label
                font-weight-bolder
                label-lg label-light-success label-inline
                note
              "
                    ></span>
                    <span
                      v-else
                      v-text="row.item.statusName"
                      class="
                label
                font-weight-bolder
                label-lg label-light-info label-inline
                note
              "
                    ></span>
                  </div>
                  <div>
                    <b-link
                      v-if="row.item.stockSlipCode"
                      v-text="row.item.stockSlipCode"
                      @click="updateImportTradeIn(row.item)"
                      class="text-sm mt-1 cursor-pointer"
                    ></b-link>
                  </div>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown
                    size="sm"
                    id="dropdown-left"
                    no-caret
                    right
                  >
                    <template slot="button-content">
                      <i class="flaticon2-settings settings-font-size pr-0"></i>
                    </template>
                    <b-dropdown-item @click="printItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-printer"
                        ></i>
                        &nbsp; In phiếu
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span class="text-sm">
                        <i class="flaticon-eye eye-font-size"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="importTradeIn(row.item)">
                      <span class="text-sm">
                        <i class="flaticon2-pen pen-font-size"></i>
                        &nbsp; Nhập máy cũ
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark font-weight-bold">
                  Tổng số:
                  {{ convertPrice(totalRow) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  :link-gen="linkGen"
                  v-show="numberOfPage >= 2"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      ref="excel-modal"
      hide-footer
      title="Xuất excel"
      id="excel-modal"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon
            small
            class="text-white"
            v-b-tooltip
          >mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import { cloneDeep, map, find, assign } from 'lodash';
import { removeAccents, convertPrice } from '@/utils/common';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import { TRADE_IN_TYPE } from '@/utils/enum';

export default {
  data() {
    return {
      TRADE_IN_TYPE,
      searchProduct: '',
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thClass: 'table-header',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          tdClass: 'td-type',
          thClass: 'table-type-header',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          tdClass: 'td-store-name',
          thClass: 'table-header',
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          tdClass: 'td-customer',
          thClass: 'table-header',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          tdClass: 'td-product-name',
          thClass: 'table-header',
        },
        {
          key: 'estimatePrice',
          label: 'Giá thu dự kiến',
          sortable: false,
          tdClass: 'td-estimate-price',
          thClass: 'table-header',
        },
        {
          key: 'finalPrice',
          label: 'Giá thu cuối cùng',
          sortable: false,
          tdClass: 'td-final-price',
          thClass: 'table-header',
        },
        {
          key: 'hasUploadImage',
          label: 'Hình ảnh',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'table-header',
        },
        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
          tdClass: 'td-note',
          thClass: 'table-header',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          tdClass: 'td-status',
          thClass: 'table-header',
        },
        {
          key: 'actions',
          label: '',
          thClass: 'table-functions-header',
          tdClass: 'td-functions',
        },
      ],
      items: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      totalRow: 1,
      onLoading: false,
      searchCustomer: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: '',
      dateTo: '',
      selectedListExcel: 'all',
      optionsStore: [],
      valueStore: [],
      listStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Đã báo giá',
        },
        {
          id: 2,
          name: 'Đã thu cũ',
        },
      ],
      selectedStatus: null,
      searchId: '',
      filterdOptionsStore: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Loader,
    BDropdownCustom,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu định giá', route: 'trade-in' },
      { title: 'Danh sách phiếu định giá' },
    ]);
  },
  methods: {
    getUploadClass(text) {
      return text === 'Chưa tải lên' ? 'text-danger' : 'text-success';
    },
    printItem(item) {
      this.$router.push({
        name: 'print-import-trade-in',
        query: { id: item.id, type: 1 },
      });
    },
    convertPrice,
    ...getToastInstance(),
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const fromDate = this.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD 00:00:00')
        : null;
      const toDate = this.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      const param = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct ? this.searchProduct.trim() : '',
        searchCustomer: this.searchCustomer ? this.searchCustomer.trim() : '',
        fromDate: fromDate,
        toDate: toDate,
        store: listStoreId,
        selectedStatus: this.selectedStatus,
        searchId: this.searchId ? this.searchId.trim() : '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('tradeIn', paramQuery).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        this.totalRow = data.data.total_row;
        this.items = data.data.data.map((element) => {
          return {
            id: element.id,
            customerId: element.customerId,
            customerName: element.customerName,
            customerPhone: element.customerPhone,
            productName: element.productName,
            imeiCode: element.imeiCode,
            estimatePrice: element.estimationBuydingPrice,
            finalPrice: element.finalBuyingPrice,
            date: element.date,
            storeName: element.storeName,
            status: element.status,
            createdBy: element.createdBy,
            statusName: element.statusName,
            tradeInType: element.tradeInType,
            tradeInTypeName: element.tradeInTypeName,
            stockSlipCode: element.stockSlipCode,
            stockSlipId: element.stockSlipId,
            note: element.note,
            hasUploadImage: element.hasUploadImage,
          };
        });
      });
      this.onLoading = false;
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-trade-in',
        query: { id: item.id },
      });
    },
    updateImportTradeIn: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-import-trade-in',
        query: { id: item.stockSlipId },
      });
      window.open(href, '_blank');
    },
    importTradeIn: function (item) {
      const { href } = this.$router.resolve({
        name: 'import-trade-in',
        query: { id: item.id },
      });
      window.open(href, '_blank');
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-trade-in',
      });
      this.fetchData();
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      const fromDate = this.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      const toDate = this.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      const listStoreId = [];
      if (this.valueStore.length) {
        this.valueStore.forEach((element) => {
          listStoreId.push(element.code);
        });
      }
      const params = {
        searchProduct: this.searchProduct,
        searchCustomer: this.searchCustomer,
        fromDate: fromDate,
        toDate: toDate,
        storeIds: listStoreId,
        status: this.selectedStatus,
        searchId: this.searchId ? this.searchId.trim() : '',
      };
      const url = `${BASE_URL}tradeIn/export-excel`;
      axios
        .get(url, {
          params: {
            ...params,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            this.makeToastFailure('Lỗi!');
          } else {
            const message = err.response.data.message;
            this.makeToastFailure(message);
          }

          this.$nprogress.done();
        });
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, (obj) => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.filterOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
        this.fetchStoreByUser();
      });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id,
              );
              if (index > -1) {
                this.optionsStore[index].checked = true;
                this.valueStore.push(this.optionsStore[index]);
              }
            }
          });
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchData();
      });
    },
    getLinkDocCustomer(item) {
      return `#/customers/update-customer?id=${item.customerId}&phoneNo=${item.customerPhone}`;
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/sass/components/_variables.bootstrap.scss';
@import 'src/assets/sass/components/_variables.custom.scss';

.list-trade-in {
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: $primary-icon-hover;
  }

  .table-header,
  .table-functions-header,
  .td-type,
  .table-type-header {
    text-align: center;
  }

  .table-header {
    width: 7%;
  }

  .table-type-header {
    width: 5%;
  }

  .table-functions-header {
    font-weight: $font-weight-bolder;
    color: $dark-75;
    width: 5%;
  }

  .td-type {
    width: 5%;
  }

  .td-customer {
    width: fit-content;
  }

  .td-product-name {
    width: fit-content;
  }

  .td-estimate-price,
  .td-final-price {
    width: fit-content;
    text-align: end;
  }

  .td-note {
    min-width: 100px;
  }

  .note {
    font-size: $font-size-sm;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word;
    white-space: normal;
  }

  .td-status {
    max-width: 120px;
    min-width: 100px;
  }

  .td-store-name {
    width: fit-content;
  }

  .text-imei-no {
    font-size: $font-size-sm;
    font-style: italic;
    color: $primary;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid $gray-200;
  }

  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }

  .productCode:hover {
    text-decoration: underline;
  }

  .icon-color {
    color: $gray-700;
  }

  .checkbox-label {
    display: block;
  }

  .function-btn {
    font-weight: $font-weight-bolder;
    width: 70px;
  }

  .td-functions {
    width: fit-content;
  }

  .cog-font-size,
  .excel-font-size,
  .settings-font-size,
  .eye-font-size,
  .pen-font-size {
    font-size: $font-size-base;
  }
}
</style>
